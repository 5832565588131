import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./routes";
import DefaultLayout from "./Layout/DefaultLayout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "./components/NotFound/NotFound";
import "ckeditor5/ckeditor5.css";

function App() {
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const { isLogin } = useSelector((state) => state.auth);

    useEffect(() => {
        if (token)
            dispatch({
                type: "USER_FETCH_REQUESTED",
                payload: {
                    path: "/user",
                },
            });
    }, [dispatch, token]);

    return (
        <div className="App">
            <Router>
                <Routes>
                    {publicRoutes.map((route, index) => {
                        const Page = route.component;
                        let Layout = DefaultLayout;
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Layout>
                                        <Page />
                                    </Layout>
                                }
                            />
                        );
                    })}

                    {privateRoutes.map((route, index) => {
                        const Page = route.component;
                        let Layout = DefaultLayout;
                        return isLogin ? (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Layout>
                                        <Page />
                                    </Layout>
                                }
                            />
                        ) : (
                            <Route
                                key="not-found"
                                path="*"
                                element={<NotFound />}
                            />
                        );
                    })}
                </Routes>
            </Router>
        </div>
    );
}

export default App;
