import { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./HomePage.module.scss";
import PostList from "../PostList/PostList";
import imagesBackground from "../../utils/randomBackground";
import Pagination from "../Pagination/Pagination";
import { useSelector, useDispatch } from "react-redux";

const cx = classNames.bind(styles);

function HomePage() {
    const [bg, setBg] = useState(0);
    const { postShow } = useSelector((state) => state.posts);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: "FETCH_POSTS_REQUESTED",
            payload: { path: "/posts" },
        });
    }, [dispatch]);

    useEffect(() => {
        const number = Math.floor(Math.random() * imagesBackground.length);
        setBg(number);
    }, []);

    return (
        <div
            className={cx("home")}
            style={{
                backgroundImage: `url(
                ${imagesBackground[bg].bg}
            )`,
                backgroundSize: "cover",
            }}
        >
            <div className={cx("wrapper")}>
                <div className="ck-content">
                    <PostList />
                </div>
                {postShow.last_page > 1 ? (
                    <Pagination links={postShow.links} />
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}

export default HomePage;
