import { createSlice } from "@reduxjs/toolkit";

const postSlice = createSlice({
    name: "posts",
    initialState: {
        createPost: {
            title: "",
            content: "",
        },
        myPosts: { data: null },
        postShow: {},
        message: "",
    },
    reducers: {
        fetchPostSuccess(state, action) {
            state.message = action.payload.message;
            state.myPosts.data = [action.payload.post];
        },
        fetchPostFail(state, action) {
            state.message = action.payload.message;
        },
        createPostSuccess(state, action) {
            state.message = action.payload.message;
        },
        createPostFail(state, action) {
            state.message = action.payload.message;
        },
        updatePostSuccess(state, action) {
            let indexPostEdit = state.myPosts.data.findIndex(
                (post) => post.id === action.payload.post.id
            );
            state.myPosts.data[indexPostEdit] = action.payload.post;
            state.message = action.payload.message;
        },
        updatePostFail(state, action) {
            state.message = action.payload.message;
        },
        fetchPostsSuccess(state, action) {
            state.message = action.payload.message;
            action.payload.posts && (state.postShow = action.payload.posts);
            action.payload.myPosts && (state.myPosts = action.payload.myPosts);
        },
        fetchPostsFail(state, action) {
            state.message = action.payload.message;
        },
        deletePostsSuccess(state, action) {
            state.message = action.payload.message;
            state.myPosts.data = state.myPosts.data.filter(
                (post) => post.id !== action.payload.id
            );
        },
        deletePostsFail(state, action) {
            state.message = action.payload.message;
        },
    },
});

export const {
    createPostSuccess,
    createPostFail,
    fetchPostsSuccess,
    fetchPostsFail,
    updatePostSuccess,
    updatePostFail,
    deletePostsSuccess,
    deletePostsFail,
    fetchPostSuccess,
    fetchPostFail,
} = postSlice.actions;

export default postSlice;
