import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import className from "classnames/bind";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

import styles from "./MyPosts.module.scss";
import Pagination from "../Pagination/Pagination";
import { useEffect } from "react";

const cx = className.bind(styles);

function MyPosts() {
    const {
        myPosts: { data, links },
    } = useSelector((state) => state.posts);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: "FETCH_POSTS_REQUESTED",
            payload: { path: "/user/posts" },
        });
    }, [dispatch]);

    const handleDeletePost = (id) => {
        dispatch({
            type: "DELETE_POST_REQUESTED",
            payload: {
                path: `/user/posts/delete/${id}`,
            },
        });
    };

    const handleHidePost = (post) => {
        dispatch({
            type: "UPDATE_POST_REQUESTED",
            payload: {
                path: `/user/posts/update/${post.id}`,
                data: {
                    isHide: !post.hide,
                },
            },
        });
    };

    return (
        <div className="container">
            <table className={cx("table")}>
                <thead>
                    <tr>
                        <th className={cx("th")}>STT</th>
                        <th className={cx("th")}>Title</th>
                        <th className={cx("th")}>Hide</th>
                        <th className={cx("th")}>Edit</th>
                        <th className={cx("th")}>Delete</th>
                    </tr>
                </thead>
                {data?.map((post, index) => {
                    return (
                        <tbody key={post.id}>
                            <tr>
                                <td className={cx("td")}>{index + 1}</td>
                                <td className={cx("td")}>{post.title}</td>
                                <td className={cx("td")}>
                                    <label
                                        style={{
                                            display: "block",
                                            width: "100%",
                                        }}
                                    >
                                        <input
                                            onChange={() =>
                                                handleHidePost(post)
                                            }
                                            type="checkbox"
                                            name="isShow"
                                            checked={post.hide}
                                            value={post.hide}
                                            id="isShow"
                                        />
                                    </label>
                                </td>
                                <td className={cx("td")}>
                                    <Link to={`/posts/edit/${post.id}`}>
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                    </Link>
                                </td>
                                <td className={cx("td")}>
                                    <button
                                        onClick={() =>
                                            handleDeletePost(post.id)
                                        }
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    );
                })}
            </table>
            <Pagination links={links} />
        </div>
    );
}

export default MyPosts;
