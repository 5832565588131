import classNames from "classnames/bind";
import styles from "./Auth.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const cx = classNames.bind(styles);

function ResetPassword() {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const isLogin = useSelector((state) => state.auth.isLogin);
    const navigate = useNavigate();
    const [fields, setFields] = useState({
        email: "user01@gmail.com",
        password: "123123123",
        password_confirmation: "123123123",
        token,
    });

    useEffect(() => {
        if (isLogin) {
            navigate("/");
        }
    }, [isLogin, navigate]);

    const handleInputChange = ({ target: { name, value } }) => {
        setFields((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch({
            type: "USER_RESET_PASSWORD_REQUESTED",
            payload: { path: "/reset-password", data: fields },
        });
    };
    return (
        <div className={cx("content")}>
            <div className={cx("form-auth")}>
                <div className={cx("left")}>
                    <h1 className={cx("heading")}>Update password</h1>
                    <form className={cx("form")} onSubmit={handleSubmit}>
                        <div className={cx("form-control")}>
                            <input
                                type="email"
                                placeholder=" "
                                className={cx("input")}
                                name="email"
                                value={fields.email}
                                onChange={handleInputChange}
                            />
                            <label className={cx("label")}>Email</label>
                        </div>
                        <div className={cx("form-control")}>
                            <input
                                type="password"
                                placeholder=" "
                                className={cx("input")}
                                name="password"
                                value={fields.password}
                                onChange={handleInputChange}
                            />
                            <label className={cx("label")}>Password</label>
                        </div>
                        <div className={cx("form-control")}>
                            <input
                                type="password"
                                placeholder=" "
                                className={cx("input")}
                                name="password_confirmation"
                                id="password_confirmation"
                                value={fields.password_confirmation}
                                onChange={handleInputChange}
                            />
                            <label
                                className={cx("label")}
                                htmlFor="password_confirmation"
                            >
                                Confirm Password
                            </label>
                            <input type="hidden" name="token" value={token} />
                        </div>
                        <button className={cx("btn")}>Update</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
