import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import styles from "./Header.module.scss";
import { useSelector } from "react-redux";
import { authSelector } from "../../redux/store";
import Menu from "../Menu/Menu";

const cx = classNames.bind(styles);

function Header() {
    const { isLogin, user } = useSelector(authSelector);
    return (
        <header className={cx("header")}>
            <div className={cx("content", "header-content")}>
                <Link to="/">Logo</Link>
                <div className={cx("search-box")}>
                    <span className={cx("icon-search")}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                    <input className={cx("input-search")} type="text" />
                </div>
                <div className={cx("action")}>
                    {isLogin ? (
                        <Menu>
                            <img
                                className={cx("avatar")}
                                src={user ? user.avatar : ""}
                                alt="avatar"
                            />
                        </Menu>
                    ) : (
                        <Link to="/login">Login</Link>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;
