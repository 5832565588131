import classNames from "classnames/bind";
import styles from "./Auth.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const cx = classNames.bind(styles);

function Login() {
    const dispatch = useDispatch();
    const isLogin = useSelector((state) => state.auth.isLogin);
    const navigate = useNavigate();
    const [fields, setFields] = useState({
        email: "user01@gmail.com",
        password: "123123123",
    });

    useEffect(() => {
        if (isLogin) {
            navigate("/");
        }
    }, [isLogin, navigate]);

    const handleInputChange = ({ target: { name, value } }) => {
        setFields((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch({
            type: "USER_LOGIN_REQUESTED",
            payload: { path: "/login", data: fields },
        });
    };
    return (
        <div className={cx("content")}>
            <div className={cx("form-auth")}>
                <div className={cx("left")}>
                    <h1 className={cx("heading")}>Sign In</h1>
                    <form className={cx("form")} onSubmit={handleSubmit}>
                        <div className={cx("form-control")}>
                            <input
                                type="email"
                                placeholder=" "
                                className={cx("input")}
                                name="email"
                                value={fields.email}
                                onChange={handleInputChange}
                            />
                            <label className={cx("label")}>Email</label>
                        </div>
                        <div className={cx("form-control")}>
                            <input
                                type="password"
                                placeholder=" "
                                className={cx("input")}
                                name="password"
                                value={fields.password}
                                onChange={handleInputChange}
                            />
                            <label className={cx("label")}>Password</label>
                        </div>
                        <button className={cx("btn")}>Signup</button>
                    </form>
                    <div>
                        <p>
                            forgot password?{" "}
                            <Link to="/forgot-password">Click Here!</Link>
                        </p>
                    </div>
                    <p className={cx("title")}>or signin with</p>
                    <div className={cx("icons")}>
                        <a href="#!">
                            <i
                                className={cx(
                                    "icon-social",
                                    "icon-fb",
                                    "fa-brands fa-facebook-f"
                                )}
                            ></i>
                        </a>
                        <a href="#!">
                            <i
                                className={cx(
                                    "icon-social",
                                    "icon-gg",
                                    "fa-brands fa-google-plus-g"
                                )}
                            ></i>
                        </a>
                        <a href="#!">
                            <i
                                className={cx(
                                    "icon-social",
                                    "icon-in",
                                    "fa-brands fa-linkedin-in"
                                )}
                            ></i>
                        </a>
                    </div>
                </div>

                <div className={cx("right")}>
                    <h2 className={cx("title")}>Welcome back!</h2>
                    <p>
                        Welcome back! We are so happy to have you here. It's
                        great to see you again. We hope you had a safe and
                        enjoyable time away.
                    </p>
                    <Link to="/register" className={cx("btn-register")}>
                        No account yet? Signup.
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Login;
