import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import className from "classnames/bind";
import styles from "./Profile.module.scss";

const cx = className.bind(styles);

function Profile() {
    const token = localStorage.getItem("token");
    const [file, setFile] = useState(null);
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const { user } = useSelector((state) => state.auth);
    const [error, setError] = useState("");
    const dispatch = useDispatch();

    const handleChangeAvatar = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            if (files[0].size > 1 * 1000 * 1024) {
                setError("File with maximum size of 1MB is allowed");
                return false;
            }
            setError("");
            setFile(e.target.files[0]);
        }
    };

    const handleSubmitAvatar = () => {
        const config = {
            headers: {
                "Content-Type":
                    "multipart/form-data; boundary=---------------------------1003363413119651595289485765",
                Authorization: `Bearer ${token}`,
            },
        };

        dispatch({
            type: "UPLOAD_AVATAR_REQUESTED",
            payload: {
                path: "/image/storeAvatar",
                data: { file },
                config,
            },
        });
    };

    const handleChangePassword = (e) => {
        e.preventDefault();

        console.log("object");

        dispatch({
            type: "USER_CHANGE_PASSWORD_REQUESTED",
            payload: {
                path: "/user/change-password",
                data: {
                    password,
                    password_confirmation: passwordConfirmation,
                    old_password: oldPassword,
                },
            },
        });

        setPassword("");
        setOldPassword("");
        setPasswordConfirmation("");
    };

    return (
        <div className="container">
            <div className={cx("inner")}>
                <h1 className={cx("title")}>Name: {user.name}</h1>
                <div className={cx("user-avatar")}>
                    <img
                        className={cx("avatar")}
                        src={user.avatar}
                        alt={user.name}
                    />
                    <input
                        type="file"
                        name="avatar"
                        id="avatar"
                        onChange={handleChangeAvatar}
                    />
                    <p className="error">{error}</p>
                    <button
                        className={cx("btn-submit")}
                        onClick={handleSubmitAvatar}
                    >
                        Save
                    </button>
                </div>
                <form
                    className={cx("password")}
                    onSubmit={handleChangePassword}
                >
                    <label htmlFor="old_password">Mật khẩu cũ</label>
                    <input
                        className={cx("input-text")}
                        type="password"
                        name="old_password"
                        id="old_password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <label htmlFor="password">Mật khẩu mới</label>
                    <input
                        className={cx("input-text")}
                        type="password"
                        name="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="passwordConfirmation">
                        Nhập lại mật khẩu mới
                    </label>
                    <input
                        className={cx("input-text")}
                        type="password"
                        name="password_confirmation"
                        id="passwordConfirmation"
                        value={passwordConfirmation}
                        onChange={(e) =>
                            setPasswordConfirmation(e.target.value)
                        }
                    />
                    <button type="submit" className={cx("btn-submit")}>
                        Update
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Profile;
