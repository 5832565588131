import classNames from "classnames/bind";
import styles from "./Auth.module.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";

const cx = classNames.bind(styles);

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch({
            type: "USER_FORGOT_PASSWORD_REQUESTED",
            payload: { path: "/forgot-password", data: { email } },
        });
    };
    return (
        <div className="container">
            <form onSubmit={handleSubmit} className={cx("forgot-password")}>
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button>submit</button>
            </form>
        </div>
    );
}

export default ForgotPassword;
