import classNames from "classnames/bind";
import styles from "./Auth.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { authSelector } from "../../redux/store";

const cx = classNames.bind(styles);

function Register() {
    const dispatch = useDispatch();
    const { isLogin } = useSelector(authSelector);
    const navigate = useNavigate();
    const [fields, setFields] = useState({
        name: "User 01",
        email: "user01@gmail.com",
        password: "123123123",
    });

    useEffect(() => {
        if (isLogin) navigate("/");
    }, [isLogin, navigate]);

    const onChangeInput = ({ target: { name, value } }) => {
        setFields((prev) => ({ ...prev, [name]: value }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch({
            type: "USER_REGISTER_REQUESTED",
            payload: { path: "/register", data: fields },
        });
    };
    return (
        <div className={cx("content")}>
            <div className={cx("form-auth")}>
                <div className={cx("left")}>
                    <h1 className={cx("heading")}>Sign Up</h1>
                    <form className={cx("form")} onSubmit={handleSubmit}>
                        <div className={cx("form-control")}>
                            <input
                                type="text"
                                placeholder=" "
                                className={cx("input")}
                                autoComplete="off"
                                name="name"
                                value={fields.name}
                                onChange={onChangeInput}
                            />
                            <label className={cx("label")}>Name</label>
                        </div>
                        <div className={cx("form-control")}>
                            <input
                                type="email"
                                placeholder=" "
                                className={cx("input")}
                                name="email"
                                value={fields.email}
                                onChange={onChangeInput}
                            />
                            <label className={cx("label")}>Email</label>
                        </div>
                        <div className={cx("form-control")}>
                            <input
                                type="password"
                                placeholder=" "
                                className={cx("input")}
                                name="password"
                                value={fields.password}
                                onChange={onChangeInput}
                            />
                            <label className={cx("label")}>Password</label>
                        </div>
                        <button className={cx("btn")}>Signup</button>
                    </form>
                    <p className={cx("title")}>or signup with</p>
                    <div className={cx("icons")}>
                        <a href="#!">
                            <i
                                className={cx(
                                    "icon-social",
                                    "icon-fb",
                                    "fa-brands fa-facebook-f"
                                )}
                            ></i>
                        </a>
                        <a href="#!">
                            <i
                                className={cx(
                                    "icon-social",
                                    "icon-gg",
                                    "fa-brands fa-google-plus-g"
                                )}
                            ></i>
                        </a>
                        <a href="#!">
                            <i
                                className={cx(
                                    "icon-social",
                                    "icon-in",
                                    "fa-brands fa-linkedin-in"
                                )}
                            ></i>
                        </a>
                    </div>
                </div>

                <div className={cx("right")}>
                    <h2 className={cx("title")}>Welcome!</h2>
                    <p>
                        Welcome! We are so happy to have you here. It's great to
                        see you again. We hope you had a safe and enjoyable time
                        away.
                    </p>
                    <Link to="/login" className={cx("btn-auth")}>
                        Already have an account? Signin.
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Register;
