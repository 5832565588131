import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducer";
import mySaga from "./saga/mysaga";

const sagaMiddleware = createSagaMiddleware();
// const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

const serializableMiddleware = {
    serializableCheck: {
        // Ignore these action types
        ignoredActions: ["UPLOAD_AVATAR_REQUESTED"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        // Ignore these paths in the state
        ignoredPaths: ["items.dates"],
    },
};

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: false,
            ...serializableMiddleware,
        }).concat(sagaMiddleware),
});
sagaMiddleware.run(mySaga);

export const authSelector = (state) => state.auth;
export default store;
