import About from "../components/About/About";
import HomePage from "../components/HomePage/HomePage";
import Login from "../components/Auth/Login";
import Register from "../components/Auth/Register";
import Profile from "../components/Profile/Profile";
import Logout from "../components/Logout/Logout";
import CreateBlog from "../components/CreateBlog/CreateBlog";
import MyPosts from "../components/MyPosts/MyPosts";
import ForgotPassword from "../components/Auth/ForgotPassword";
import ResetPassword from "../components/Auth/ResetPassword";

const publicRoutes = [
    { path: "/", component: HomePage },
    { path: "/forgot-password", component: ForgotPassword },
    { path: "/reset-password", component: ResetPassword },
    { path: "/about", component: About },
    { path: "/login", component: Login },
    { path: "/register", component: Register },
];

const privateRoutes = [
    { path: "/create-blog", component: CreateBlog },
    { path: "/posts/:edit/:id", component: CreateBlog },
    { path: "/my-posts", component: MyPosts },
    { path: "/logout", component: Logout },
    { path: "/profile", component: Profile },
];

export { publicRoutes, privateRoutes };
