import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch({
            type: "LOGOUT_USER",
            payload: {
                path: "/user/logout",
            },
        });
        navigate("/");
    }, [dispatch, navigate]);

    return <Loading />;
};
export default Logout;
