import classNames from "classnames/bind";

import { useSelector } from "react-redux";

import styles from "./PostList.module.scss";
import Post from "../Post/Post";

const cx = classNames.bind(styles);

function PostList() {
    const { postShow } = useSelector((state) => state.posts);
    return (
        <div className={cx("wrapper")}>
            {postShow.data?.map((post) => {
                return <Post key={post.id} post={post} />;
            })}
        </div>
    );
}

export default PostList;
