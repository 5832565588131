import Tippy from "@tippyjs/react/headless";
import classNames from "classnames/bind";
import styles from "./Menu.module.scss";
import { Link } from "react-router-dom";

const cx = classNames.bind(styles);

function Menu({ children }) {
    return (
        <Tippy
            interactive
            placement="bottom-end"
            render={(attrs) => (
                <ul className={cx("menu")} tabIndex="-1" {...attrs}>
                    <li className={cx("item")}>
                        <Link className={cx("link")} to="/profile">
                            Profile
                        </Link>
                    </li>
                    <li className={cx("item")}>
                        <Link className={cx("link")} to="/create-blog">
                            Viết blog
                        </Link>
                    </li>
                    <li className={cx("item")}>
                        <Link className={cx("link")} to="/my-posts">
                            Bài viết của tôi
                        </Link>
                    </li>

                    <li className={cx("item")}>
                        <Link className={cx("link")} to="/logout">
                            Logout
                        </Link>
                    </li>
                </ul>
            )}
        >
            {children}
        </Tippy>
    );
}
export default Menu;
