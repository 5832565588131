import classNames from "classnames/bind";

import styles from "./Pagination.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { createMarkup } from "../../utils/makup";

const cx = classNames.bind(styles);

function Pagination({ links }) {
    const dispatch = useDispatch();

    const handleChangePage = (item) => {
        dispatch({
            type: "FETCH_POSTS_REQUESTED",
            payload: { path: item.url },
        });
    };
    return (
        <div className={cx("paginate")}>
            {links?.map((item, index) => {
                return (
                    <span
                        className={cx(
                            "paginate-item",
                            item.active ? "active" : ""
                        )}
                        key={index}
                        onClick={() => handleChangePage(item)}
                        dangerouslySetInnerHTML={createMarkup(item.label)}
                    ></span>
                );
            })}
        </div>
    );
}

export default Pagination;
