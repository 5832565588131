import Header from "../components/Header/Header";

function DefaultLayout({ children }) {
    return (
        <>
            <Header />
            {children}
        </>
    );
}
export default DefaultLayout;
