import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: {},
        isLogin: false,
        isLoading: false,
        token: null,
        message: "",
    },
    reducers: {
        logoutUserSuccess(state, action) {
            state.user = {};
            state.isLogin = false;
            state.message = action.payload;
        },
        logoutUserFail(state, action) {
            state.message = action.payload;
        },
        getUserSuccess(state, action) {
            state.user = action.payload;
            state.isLogin = true;
        },
        loginSuccess(state, action) {
            state.user = action.payload;
            state.token = action.payload.token;
            state.isLogin = true;
        },
        registerSuccess(state, action) {
            state.user = action.payload;
            state.isLogin = true;
        },
        loginFail(state, action) {
            state.message = action.payload;
        },
        registerFail(state, action) {
            state.message = action.payload;
        },
        uploadAvatarSuccess(state, action) {
            state.user = action.payload;
        },
    },
});

export const {
    loginSuccess,
    registerSuccess,
    registerFail,
    loginFail,
    getUserSuccess,
    logoutUserSuccess,
    logoutUserFail,
} = authSlice.actions;
export default authSlice;
