import axios from "axios";

const httpRequest = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API,
});

// Set the AUTH token for any request
httpRequest.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});

export const get = async ({ path }) => {
    const res = await httpRequest.get(path);
    return res.data;
};

export const post = async ({ path, data, config = {} }) => {
    const res = await httpRequest.post(path, data, config);
    return res.data;
};

export const put = async ({ path, data, config = {} }) => {
    const res = await httpRequest.put(path, data, config);
    return res.data;
};

export const remove = async ({ path, config = {} }) => {
    const res = await httpRequest.delete(path, config);
    return res.data;
};

export default httpRequest;
