import classNames from "classnames/bind";

import { createMarkup } from "../../utils/makup";
import styles from "./Post.module.scss";

const cx = classNames.bind(styles);

function Post({ post }) {
    return (
        <div className={cx("post-item")}>
            {/* <h1 className={cx("post-title")}>{post.title}</h1> */}
            <div
                className={cx("post-content")}
                dangerouslySetInnerHTML={createMarkup(post.content)}
            />
        </div>
    );
}
export default Post;
